import React, { useEffect, useState, useRef } from 'react';
import AceEditor from 'react-ace';
import { useIonLoading } from '@ionic/react';
import ReactReplView from './ReactReplView.jsx';
import { Tab } from 'react-tabs';
import Sidebar from './Sidebar.jsx';
import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/snippets/python';
import 'ace-builds/src-noconflict/theme-nord_dark';
import 'ace-builds/src-noconflict/ext-language_tools';
import { loadPyodide } from 'pyodide';
import logsIcon1 from './img/logs.svg';
import runall from './img/runall.svg';
import stopIcon from './img/stopIcon.svg';
import runIcon from './img/runIcon.svg';
import debugIcon from './img/bugIcon.svg';
import Debugger from './Debugger.jsx';
import expandIcon from './img/expandIcon.svg';
import saveIcon from './img/saveIcon.svg';
import expandBig from './img/expandbig.svg';
import { handleSpriteCommands } from './spriteCommands.js';
import spriteCode from './runpythonsprite.js';

const PythonEditor = ({ }) => {
  const [codeInput, setCodeInput] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isPyodideLoaded, setIsPyodideLoaded] = useState(false);
  const [presentLoading] = useIonLoading();
  const [outputArray, setOutputArray] = useState([{ type: '', value: '' }]);
  const [pyodide, setPyodide] = useState(null);
  const [showOptions1, setShowOptions1] = useState(false);
  const [showOptions2, setShowOptions2] = useState(false);
  const [stopRequested, setStopRequested] = useState(false);
  const [showDebugger, setShowDebugger] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [imageSrc, setImageSrc] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [allFiles, setAllFiles] = useState([]);
  const canvasRef = useRef(null);

  useEffect(() => {
    if (selectedFile) {
      handleOpenEditor(selectedFile);
    }
  }, [selectedFile]);

  const handleButtonClick1 = () => {
    setIsExpanded(!isExpanded);
  };


  const handleRunAllClick = () => {
    for (const file of allFiles) {
      handleOpenEditor(file);
      runPyCode();
    }
  };

  const handleButtonClick = () => {
    setShowDebugger(!showDebugger);
  };

  const toggleOptions1 = () => {
    setShowOptions1(!showOptions1);
  };

  const toggleOptions2 = () => {
    setShowOptions2(!showOptions2);
  };

  const handleStopClick = () => {
    setStopRequested(true); // Set stopRequested to true when stop button is clicked
  };

  useEffect(() => {
    const loadPythonInReact = async () => {
      if (!isLoading) {
        setIsLoading(true);
        return;
      }

      if (!isPyodideLoaded) {
        presentLoading('Loading... Pyodide', 2000, 'crescent');

        const pyodideInstance = await loadPyodide({
          indexURL: 'https://cdn.jsdelivr.net/pyodide/v0.23.4/full/',
          stdin: window.prompt,
          stdout: (text) => {
            let data = { type: 'output', value: text.toString() };
            setOutputArray((outputArray) => [...outputArray, data]);
          },
          stderr: (text) => {
            let data = { type: 'error', value: text.toString() };
            setOutputArray((outputArray) => [...outputArray, data]);
          },
        });

        // call runpythonsprite.js file
        await pyodideInstance.runPythonAsync(spriteCode);

        // Load additional packages
        await pyodideInstance.loadPackage('numpy');
        await pyodideInstance.loadPackage('pandas');
        await pyodideInstance.loadPackage('micropip');
        await pyodideInstance.loadPackage('opencv-python');
        await pyodideInstance.loadPackage('matplotlib');
        await pyodideInstance.loadPackage('distutils');
        // await pyodideInstance.loadPackage('execjs');

        setPyodide(pyodideInstance);
        setIsPyodideLoaded(true);
      }
    };

    loadPythonInReact();
  }, [isLoading, isPyodideLoaded, presentLoading]);

  const runPyCode = async () => {
    if (!pyodide) {
      console.error('Pyodide is not loaded yet.');
      return;
    }
  
    if (!codeInput.trim()) {
      console.error('Code input is empty.');
      return;
    }
  
    try {
      const result = await pyodide.runPythonAsync(codeInput);
      const outputArray = result !== undefined ? result.toString() : "Python code did not return a value.";
      if (result !== undefined && result.error) {
                  let data = { type: 'error', value: result.error };
                  setOutputArray((outputArray) => [...outputArray, data]);
                }
                window.dispatchEvent(new CustomEvent('pythonCodeResult', { detail: outputArray }));

      // Extract sprite commands from the Python code
      const spriteCommands = extractSpriteCommands(codeInput);
      if (spriteCommands) {
        handleSpriteCommands({ command: codeInput, result: outputArray });
      }
    } catch (error) {
      console.error('Error running Python code:', error);
    }
  };
  
  // Helper function to extract sprite commands from Python code
  const extractSpriteCommands = (code) => {
    // Implement logic to extract sprite commands from the Python code
    // For example, you can use regular expressions to extract lines that start with "sprite."
    const spriteCommands = [];
    const lines = code.split('\n');
    for (const line of lines) {
      if (line.trim().startsWith('sprite.')) {
        spriteCommands.push(line.trim());
      }
    }
    return spriteCommands.join('\n');
  };

  async function termAction(action, e) {
    if (action === "onClear") {
      setOutputArray([{ type: "", value: "" }]);
    }

    if (action === "onSubmit") {
      if (e === "clear" || e === "clear()") {
        setOutputArray([{ type: "", value: "" }]);
        return;
      }

      if (e === "" || e === undefined || e === null) {
        return;
      }

      let data = { type: "input".toString(), value: e.toString() };
      setOutputArray((outputArray) => [...outputArray, data]);

      if (!pyodide) {
        console.error('Pyodide is not loaded yet.');
        return;
      }

      let namespace = pyodide.globals.get("dict")();
      pyodide.runPython(
        `
    import sys 
    from pyodide.ffi import to_js
    from pyodide.console import PyodideConsole, repr_shorten, BANNER
    import main
    BANNER = "Welcome to the Pyodide terminal emulator 🐍\\n" + BANNER
    pyconsole = PyodideConsole(main.dict)
    import builtins
    async def await_fut(fut):
      res = await fut
      if res is not None:
        builtins._ = res
      return to_js([res], depth=1)
    def clear_console():
      pyconsole.buffer = []
`,
        { globals: namespace }
      );

      let await_fut = namespace.get("await_fut");
      let pyconsole = namespace.get("pyconsole");

      pyconsole.stdout_callback = (s) => {
        let data = { type: "output".toString(), value: s.toString() };
        setOutputArray((outputArray) => [...outputArray, data]);
      };

      pyconsole.stderr_callback = (s) => {
        let data = { type: "error".toString(), value: s.toString() };
        setOutputArray((outputArray) => [...outputArray, data]);
      };

      try {
        let fut = pyconsole.push(e.toString());
        let result = await await_fut(fut);
        if (result.length > 0) {
          if (result[0] !== undefined) {
            let data = { type: 'output'.toString(), value: repr_shorten(result[0]) };
            setOutputArray((outputArray) => [...outputArray, data]);
          }
        }
      } catch (err) {
        let data = { type: 'error'.toString(), value: err.toString() };
        setOutputArray((outputArray) => [...outputArray, data]);
      }
    }

    if (action === "onCopy") {
      const lastOutput = outputArray[outputArray.length - 1];
      if (lastOutput && lastOutput.value) {
        navigator.clipboard.writeText(lastOutput.value).then(
          () => console.log('Copied to clipboard'),
          (err) => console.error('Could not copy: ', err)
        );
      }
    }

    if (action === "onSearch") {
      const searchTerm = prompt("Enter the term to search:");
      if (searchTerm) {
        const results = outputArray.filter(line => line.value.includes(searchTerm));
        if (results.length > 0) {
          console.log('Search results:', results);
          alert(`Found${results.length}`, results);
          // Optionally, setFilteredResults(results); if you want to manage state
          scrollToResult(0); // Scroll to the first result
        } else {
          alert('No results found');
        }
      }
    }
  }

  const scrollToResult = (index) => {
    if (index >= 0 && index < outputArray.length) {
      document.querySelectorAll('.output-line')[index]?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  return (
    <div style={{ display: 'flex', flexGrow: '1' }}>
      <div id="python-editor-container" style={{ height: '100%', width: '100%' }}>
        <div style={{ display: isExpanded ? 'none' : 'flex', paddingBottom: '5px', height: '65vh' }}>
          <Sidebar
            // style={{maxWidth:'10%', minWidth:'10%'}}
            toggleOptions1={toggleOptions1}
            toggleOptions2={toggleOptions2}
            showOptions1={showOptions1}
            showOptions2={showOptions2}
            setCodeInput={setCodeInput}
            getFiles={setAllFiles}
            setImageSrc={setImageSrc}
            codeInput={codeInput}
          />
          {imageSrc ? (
            <div style={{ width: showDebugger ? '80%' : ' 100%', textAlign: 'center', marginTop: '10%' }}>
              <img src={imageSrc} alt="Selected" style={{ maxWidth: '100%', maxHeight: '100%' }} />
            </div>
          ) : (
            <AceEditor id="show-file" 
              onChange={(newValue) => setCodeInput(newValue)}
              style={{ width: showDebugger ? '80%' : '100%', height: '100%', boxShadow: 'rgb(118, 118, 118) 1px 0px 4px', borderTopRightRadius: '1%', borderBottomRightRadius: '1%', paddingBottom: '5px' }}
              placeholder=""
              mode="python"
              name="code_editor"
              height="600px"
              fontSize={18}
              editorProps={{ $blockScrolling: true }}
              showPrintMargin={true}
              showGutter={true}
              highlightActiveLine={true}
              value={codeInput}
              setOptions={{
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                enableSnippets: false,
                showLineNumbers: true,
                tabSize: 2,
              }}>
            </AceEditor>
          )}
        </div>

        <div id="python-editor-output" style={{ width: '100%', height: '100%', boxShadow: 'rgb(118, 118, 118) 1px 0px 4px', borderTopRightRadius: '2%', borderBottomRightRadius: '2%' }}>
          <div className='logos' style={{ display: 'flex', marginTop: '10px', alignItems: 'center', justifyContent: 'space-between' }}>
            <Tab
              className="react-tabs_react-tabs_tab_1ktU terminal_tab_8e-XY react-tabs_react-tabs__tab--selected_2o0LJ terminal_is-selected_3neEd"
              id="react-tabs-10"
              style={{ borderRadius: '5%', width: '75px', backgroundColor: 'rgb(219 227 242)', marginLeft: "10px", border: '1px solid hsla(0, 0%, 0%, 0.15)', height: '35px', display: 'flex' }}
            >
              <img draggable={false} src={logsIcon1} style={{ height: '25px', width: '25px', marginTop: '4px' }} />
              <div style={{ marginTop: '8px', marginLeft: '7px', color: '#2c236c' }}>Logs</div>
            </Tab>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {/* run button */}
              <button type="button" onClick={runPyCode}
                style={{ borderTopLeftRadius: '5%', borderBottomLeftRadius: '5%', display: 'flex', border: '1px solid hsla(0, 0%, 0%, 0.15)', backgroundColor: 'rgb(219 227 242)' }}>
                <img draggable={false} src={runIcon} style={{ width: '25px', height: '25px', cursor: 'pointer', margin: '2px', }} />
                <div style={{ marginTop: "7px", color: '#2c236c' }}>Run</div>
              </button>
              {/* runn all buttton  */}
              <button className="btn-text-right" type="button" onClick={handleRunAllClick} style={{ display: 'flex', border: '1px solid hsla(0, 0%, 0%, 0.15)', backgroundColor: 'rgb(219 227 242)' }}>
                <img draggable={false} src={runall} style={{ width: '25px', height: '25px', cursor: 'pointer', margin: '2px', }} />
                <div style={{ marginTop: "7px", color: '#2c236c' }}>Run All</div>
              </button>
              {/* stop button */}
              <button
                onClick={handleStopClick}
                style={{ borderTopRightRadius: '5%', borderBottomRightRadius: '5%', display: 'flex', border: '1px solid hsla(0, 0%, 0%, 0.15)', backgroundColor: 'rgb(219 227 242)' }}  >
                <img draggable={false} src={stopIcon} style={{ width: '25px', height: '25px', cursor: 'pointer', margin: '2px', }} />
                <div style={{ marginTop: "7px", color: '#2c236c' }}>Stop</div>
              </button>
              {/* debugger buttton  */}
              <button onClick={handleButtonClick} style={{ marginLeft: '10px', borderRadius: '5%', border: '1px solid hsla(0, 0%, 0%, 0.15)', backgroundColor: 'rgb(219 227 242)' }}>
                <img draggable={false} src={debugIcon} alt="Debug Icon" style={{ width: '25px', height: '25px', cursor: 'pointer', margin: '2px', }} />
              </button>

              {/* expand button  */}
              <button onClick={handleButtonClick1} style={{ marginLeft: '10px', marginRight: '10px', backgroundColor: 'rgb(219 227 242)', borderRadius: '5%', border: '1px solid hsla(0, 0%, 0%, 0.15)' }}>
                <img draggable={false} src={isExpanded ? expandBig : expandIcon} style={{ width: '25px', height: '25px', margin: '2px', cursor: 'pointer' }} alt="Expand Icon" />
              </button>
            </div>
          </div>

          <div style={{ marginLeft: '10px', height: '100%' }}>
            <div style={{ marginTop: '10px', transition: 'height 0.3s ease', height: isExpanded ? '100%' : '20%', margintop: '20px', maxwidth: '900px' }}>
              {/* terminal */}
              <ReactReplView title={''}
                tabs={[]}
                onSubmit={(e) => termAction("onSubmit", e)}
                lines={outputArray}
                onChangeTab={(e) => termAction("onChangeTab", e)}
                onClear={() => termAction("onClear")}
                isExpanded={isExpanded} />
            </div>
          </div>
        </div>
      </div>
      {showDebugger && <Debugger />}
    </div >
  );
};
export default PythonEditor;