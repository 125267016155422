import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { openExtensionLibrary } from '../../reducers/modals.js';
// import WarningModal from './WarningModel.jsx';
// import extensions from '../../lib/libraries/extensions/index.jsx';
import ProjectDropdown from './projectDropdown.jsx';
import PropTypes from 'prop-types';
import DropdownRight from './dropdownRight.jsx';
// import LibraryItem from '../../containers/library-item.jsx';
import pythonIcon from './img/pythonIcon.svg';
import imageIcon from './img/imageIcon.svg';
import textIcon from './img/textIcon.svg';
import CSVIcon from './img/CSVIcon.svg';
import mainBicon from './img/mainBicon.svg';
import moduleIcon from './img/moduleIcon.svg';
// import pipIcon from './img/pipIcon.svg';
import SideCSV from './img/sideCsv.svg';
import SideImg from './img/sideImg.svg';
import SideTxt from './img/sideTxt.svg';
import SidePy from './img/sidePy.svg';
import extensionIcon from './img/extensionIcon.svg';

const Sidebar = ({ sprites, vm, toggleOptions1, toggleOptions2, showOptions1, showOptions2, onOpenExtensionLibrary, setCodeInput, setImageSrc }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedExtension, setSelectedExtension] = useState(null);
  const [hoveredButton, setHoveredButton] = useState(null);
  const [isInputVisible, setIsInputVisible] = useState(false);
  const [inputType, setInputType] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [newFileName, setNewFileName] = useState("");
  const [renamingFile, setRenamingFile] = useState(null);
  const [copiedFile, setCopiedFile] = useState(null);
  const [cutFile, setCutFile] = useState(null);
  const [contextMenu, setContextMenu] = useState({ isOpen: false, file: null, type: '' });
  const inputRef = useRef(null);
  const handleMouseEnter = (button) => setHoveredButton(button);
  const handleMouseLeave = () => setHoveredButton(null);
  const [loadedExtensions, setLoadedExtensions] = useState([]);

  useEffect(() => {
    if (vm && vm.extensionManager) {
      console.log('VM is available:', vm);
      const extensions = vm.extensionManager._loadedExtensions;
      console.log('Loaded extensions:', extensions);
      const keysArray = Array.from(extensions);
      console.log(keysArray) // Log extensions
      setLoadedExtensions(keysArray.map(([key]) => ({
        name: key
      })));
    } else {
      console.log('VM is not available or extensionManager is undefined');
    }
  }, [vm]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        if (inputValue.trim() === '') {
          setIsInputVisible(false);
        }
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [inputValue]);

  const handleOpenEditor = (file) => {
    const fileName = file.name;
    const fileExtension = fileName.substring(fileName.lastIndexOf('.'));

    if (fileName === 'Stage.py') {
      setCodeInput(`sprite = Sprite('Stage')\n`);
      setImageSrc('');
    } else if (fileExtension === '.txt' || fileExtension === '.csv' || fileExtension === '.py') {
      const reader = new FileReader();
      reader.onload = (e) => {
        setCodeInput(e.target.result);
      };
      reader.readAsText(file);
      setImageSrc('');
    } else if (file.type && file.type.startsWith('image')) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImageSrc(e.target.result);
      };
      reader.readAsDataURL(file);
    } else {
      setCodeInput(`sprite = Sprite('${fileName}')\n`);
      setImageSrc('');
    }
  };

  const handleCreatePythonFile = (fileName) => {
    // Create a new file object
    const newFileContent = '# Your Python code here\n';
    const newFile = new Blob([newFileContent], { type: 'text/plain' });
    newFile.name = fileName + '.py';
    newFile.lastModified = new Date();

    setSelectedFiles(prevFiles => [...prevFiles, newFile]);
    if (selectedFile === newFile.name) {
      setCodeInput(newFile.content);
    }
  };

  const handleCreateTextFile = (fileName) => {
    // Create a new file object
    const newFileContent = '# write Your text here\n';
    const newFile = new Blob([newFileContent], { type: 'text/plain' });
    newFile.name = fileName + '.txt';
    newFile.lastModified = new Date();

    setSelectedFiles(prevFiles => [...prevFiles, newFile]);
    if (selectedFile === newFile.name) {
      setCodeInput(newFile.content);
    }
  };

  const handleFileClick = (file) => {
    if (file && file.name) {
      handleOpenEditor(file); // Updated to use handleOpenEditor
      setSelectedFile(file.name);
    } else {
      console.error('Invalid file object', file);
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const fileIcons = {
    '.py': SidePy,
    '.txt': SideTxt,
    '.csv': SideCSV,
    'image': SideImg,
  };

  const handleFileInputChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
  };

  const handleAddExtensionClick = () => {
    // LibraryItem.handleClick(e);
    onOpenExtensionLibrary();
    console.log(onOpenExtensionLibrary());
    console.log('Extension library opened');

  };

  const getFileIcon = (file) => {
    const fileExtension = file.name.substring(file.name.lastIndexOf('.'));
    if (fileIcons[fileExtension]) {
      return fileIcons[fileExtension];
    }
    const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.svg']; // add more extensions as needed
    if (imageExtensions.includes(fileExtension.toLowerCase())) {
      return fileIcons['image'];
    }
    return null;
  };

  const handleSelectOption = (option, file) => {
    if (option === 'Create a empty python file') {
      setInputType('.py');
      setIsInputVisible(true);
    } else if (option === 'Create a empty text file') {
      setInputType('.txt');
      setIsInputVisible(true);
    } else if (option === 'Delete') {
      setSelectedFiles(prevFiles => prevFiles.filter(f => f.name !== file.name));
    } else if (option === 'Export') {
      downloadFile(file);
    } else if (option === 'Rename') {
      const nameWithoutExtension = file.name.replace(/\.[^/.]+$/, "");
      setRenamingFile(file);
      setNewFileName(nameWithoutExtension);
    } else if (option === 'Copy') {
      setCopiedFile(file);
    } else if (option === 'Cut') {
      setCutFile(file);
      setSelectedFiles(selectedFiles.filter(f => f !== file));
    } else if (option === 'Paste') {
      if (copiedFile) {
        const uniqueName = generateUniqueFileName(copiedFile.name);
        setSelectedFiles([...selectedFiles, { ...copiedFile, name: uniqueName }]);
        setCopiedFile(null);
      } else if (cutFile) {
        const uniqueName = generateUniqueFileName(cutFile.name);
        setSelectedFiles([...selectedFiles, { ...cutFile, name: uniqueName }]);
        setCutFile(null);
      }
    }
  };

  const generateUniqueFileName = (name) => {
    const baseName = name.replace(/\.[^/.]+$/, "");
    const extension = name.substring(name.lastIndexOf('.'));
    let uniqueName = name;
    let counter = 1;
    while (selectedFiles.some(file => file.name === uniqueName)) {
      uniqueName = `${baseName}(${counter}).${extension}`;
      counter++;
    }
    return uniqueName;
  };

  const downloadFile = (file) => {
    const blob = new Blob([file], { type: file.type });
    const fileURL = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = fileURL;
    link.download = file.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleRenameFile = (file, newName) => {
    const extension = file.name.split('.').pop();
    const updatedName = `${newName}.${extension}`;
    const updatedFiles = selectedFiles.map((f) => {
      if (f === file) {
        return { ...f, name: updatedName };
      }
      return f;
    });
    setSelectedFiles(updatedFiles);
    setRenamingFile(null);
    setNewFileName("");
  };

  const handleRenameChange = (e) => {
    setNewFileName(e.target.value);
  };

  const handleRenameSubmit = (e, file) => {
    if (e.key === 'Enter') {
      handleRenameFile(file, newFileName);
    }
  };

  const handleInputKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (inputType === '.py') {
        handleCreatePythonFile(inputValue);
      } else if (inputType === '.txt') {
        handleCreateTextFile(inputValue);
      }
      setInputValue('');
      setIsInputVisible(false);
    }
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleRightClick = (event, file) => {
    event.preventDefault();
    setSelectedFile(file);
    setContextMenu({
      isOpen: true,
      file: file,
      position: { x: event.pageX, y: event.pageY },
      type: 'file'
    });
  };

  const handleClose1 = () => {
    setContextMenu({ isOpen: false, file: null, position: { x: 0, y: 0 }, type: '' });
    // setContextMenu(null);
  };

  const sidebarStyle = {
    color: 'white',
    paddingBottom: '5px',
    borderRight: '1px solid #80808082',
  };

  const projectFilesStyle = {
    backgroundColor: 'rgb(231, 240, 255)',
    color: 'black',
    height: '75%',
    overflow: 'scroll',
    scrollbarWidth: 'none'
  };

  const SidebarPart = {
    height: "50%"
  }

  const dropdownIconStyle = (button) => ({
    width: '30px',
    height: '30px',
    cursor: 'pointer',
    borderRadius: '50%',
    backgroundColor: hoveredButton === button ? 'rgb(15 189 140)' : 'rgb(43, 37, 109)',
  });

  const actionButtonStyle = (button) => ({
    marginLeft: '15px',
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    backgroundColor: hoveredButton === button ? 'rgb(15 189 140)' : 'rgb(43, 37, 109)',
    border: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    boxShadow: 'rgb(43 37 109 / 42%) 0px 0px 4px 4px',
  });

  const actionIconStyle = {
    width: '25px',
    height: '25px',
    cursor: 'pointer',
    borderRadius: '50%',
  };

  const headingStyle = {
    backgroundColor: 'rgb(218 227 242)',
    padding: '7px',
    height: '30px',
    borderBottom: '1px solid #80808082',
    color: '#575e75bf'
  }

  const fileList = {
    display: 'flex',
    borderBottom: '1px solid rgb(198 205 214)',
    height: '25px',
    padding: '4px',
    paddingLeft: '10px',
    cursor: 'pointer',
  }

  const fileImg = {
    width: '20px',
    height: '20px',
    marginRight: '5px',
    cursor: 'pointer',
  }

  const bottomDiv = {
    backgroundColor: 'rgb(231, 240, 255)',
    height: '50px',
    padding: '4px',
    display: 'flex',
    overflow: 'hidden',
    borderBottom: '1px solid #80808082',
    boxShadow: 'rgb(118, 118, 118) 1px 0px 4px'
  }

  const hideButtonDiv = {
    padding: '6px',
    marginLeft: '5px',
    marginRight: '15px',
    justifyContent: 'space-between',
    display: 'flex'
  }

  const hideButton = {
    border: 'none',
    display: 'block',
    width: '30px',
    height: '30px',
  }

  const headingMain = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'rgb(218 227 242)',
    padding: '7px',
    height: '30px',
    borderBottom: '1px solid #80808082',
    color: '#575e75bf',
  };

  return (
    <div className="sidebar" style={sidebarStyle}>
      {/* Project files */}
      <div style={SidebarPart}>
        <div style={headingMain}>
          <div>Project Files</div>
          <ProjectDropdown onSelectOption={handleSelectOption} copiedFile={copiedFile} cutFile={cutFile} />
        </div>
        <div style={projectFilesStyle}>
          <div id='file-/Stage.py'
            style={{
              ...fileList, backgroundColor: selectedFile === 'Stage.py' ? '#8781ab' : 'transparent',
              color: selectedFile === 'Stage.py' ? 'white' : '#575e75bf',
            }}
            title="Please use sprite/backdrop setting for file operations."
            onClick={() => handleFileClick({ name: 'Stage.py' })}>
            <img src={SidePy} style={fileImg} /><div>Stage.py</div></div>
          <div >
            {sprites.map((sprite, index) => (
              <div
                style={{
                  ...fileList, backgroundColor: selectedFile === sprite.name ? '#8781ab' : 'transparent',
                  color: selectedFile === sprite.name ? 'white' : '#575e75bf',
                }}
                key={index}
                title="Please use sprite/backdrop setting for file operations."
                onClick={() => handleFileClick(sprite)}>
                <img src={SidePy} style={fileImg} />{sprite.name}
              </div>
            ))}
          </div>

          {selectedFiles.map((file, index) => (
            <div key={index}
              style={{
                ...fileList, backgroundColor: selectedFile === file.name ? '#8781ab' : 'transparent',
                color: selectedFile === file.name ? 'white' : '#575e75bf',
              }}
              onClick={() => handleFileClick(file)}
              onContextMenu={(event) => handleRightClick(event, file)}>
              <img src={getFileIcon(file)} style={{ width: '20px', height: '20px', marginRight: '5px' }} />
              {renamingFile === file ? (
                <input
                  type="text"
                  value={newFileName}
                  onChange={handleRenameChange}
                  onKeyDown={(e) => handleRenameSubmit(e, file)}
                  onBlur={() => handleRenameFile(file, newFileName)}
                  autoFocus
                />
              ) : (
                <div>{file.name}</div>
              )}
            </div>
          ))}

          {contextMenu.isOpen && (
            <DropdownRight
              position={contextMenu.position}
              file={contextMenu.file}
              onClose={handleClose1}
              onSelectOption={handleSelectOption}
              className="your-dropdown-class"
            />
          )}

          {isInputVisible && (
            <div style={{ borderBottom: '1PX Solid #80808082', paddingLeft: '10px', height: '25px', paddingTop: '2px', paddingBottom: '2px' }}>
              <input
                ref={inputRef}
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={handleInputKeyDown}
                placeholder={'Enter file name'}
                style={{
                  paddingTop: '2px',
                  paddingBottom: '2px',
                  paddingLeft: '5px',
                  color: '#575e75bf',
                  cursor: 'pointer',
                  border: 'none',
                  backgroundColor: '#e7f0ff'
                }}
                autoFocus
              />
            </div>
          )}
        </div>

        <div style={bottomDiv}>
          <div>
            <div aria-label="Add a new file" className="action-menu_button" title="Add a new file" style={actionButtonStyle('button7')} onMouseEnter={() => handleMouseEnter('button7')} onMouseLeave={handleMouseLeave} onClick={toggleOptions1}>
              <img className="action-menu_main-icon_1ktMc" draggable="false" src={mainBicon} alt="Add file" style={actionIconStyle} />
            </div>
          </div>
          <div id='fileInput1' style={{ width: '180px', float: 'right' }}>
            {showOptions1 && (
              <div className="options-dropdown" style={hideButtonDiv}>
                <div className='button' style={hideButton} >
                  <img draggable={false} className="dropdown-icon" src={pythonIcon} alt="Option icon" title="Add a new Python file" onMouseEnter={() => handleMouseEnter('button1')} onMouseLeave={handleMouseLeave} style={dropdownIconStyle('button1')} onClick={() => {
                    document.getElementById('fileInput').setAttribute('accept', '.py');
                    document.getElementById('fileInput').click();
                  }} />
                  <input type="file" accept=".py" id="fileInput" style={{ display: 'none' }} multiple onChange={handleFileInputChange} />
                </div>
                <div className='button' style={hideButton}>
                  <img draggable={false} className="dropdown-icon" src={imageIcon} alt="Option icon" title="Add a new Image file" onMouseEnter={() => handleMouseEnter('button2')} onMouseLeave={handleMouseLeave} style={dropdownIconStyle('button2')} onClick={() => {
                    document.getElementById('fileInput').setAttribute('accept', 'image/png, image/gif, image/jpeg, image/svg+xml');
                    document.getElementById('fileInput').click();
                  }} />
                  <input type="file" accept="image/png, image/gif, image/jpeg" id="fileInput" style={{ display: 'none' }} multiple onChange={handleFileInputChange} />
                </div>
                <div className='button' style={hideButton}>
                  <img draggable={false} className="dropdown-icon" src={textIcon} alt="Option icon" title="Add a new Text file" onMouseEnter={() => handleMouseEnter('button3')} onMouseLeave={handleMouseLeave} style={dropdownIconStyle('button3')} onClick={() => {
                    document.getElementById('fileInput').setAttribute('accept', 'text/plain');
                    document.getElementById('fileInput').click();
                  }} />
                  <input type="file" id="fileInput" accept="text/plain" style={{ display: 'none' }} multiple onChange={handleFileInputChange} />
                </div>
                <div className='button' style={hideButton}>
                  <img draggable={false} className="dropdown-icon" src={CSVIcon} alt="Option icon" title="Add a new CSV file" onMouseEnter={() => handleMouseEnter('button4')} onMouseLeave={handleMouseLeave} style={dropdownIconStyle('button4')} onClick={() => {
                    document.getElementById('fileInput').setAttribute('accept', '.csv');
                    document.getElementById('fileInput').click();
                  }} />
                  <input type="file" accept=".csv" id="fileInput" style={{ display: 'none' }} multiple onChange={handleFileInputChange} />
                </div>
              </div>
            )}
          </div>
        </div>
        <div style={headingStyle}>Modules/libraries</div>
        <div style={projectFilesStyle}>
          {/* that div for a extension */}
          <div className='modulesname' title="Modules cannot be edited/opened." style={{ ...fileList, color: '#575e75bf' }}>
            <img src={SidePy} style={fileImg} /><div>Sprite</div></div>
          <div className='exid'>
            {
              loadedExtensions.map((extension, index) => (
                <div key={index} className='modulesname' title="Modules cannot be edited/opened." style={{ ...fileList, color: '#575e75bf' }} >
                  <img src={SidePy} style={fileImg} />
                  {/* <div>{extension.name}({extension.extensionId})</div> */}
                  <div>{extension.name}</div>
                </div>
              ))
            }
          </div>
        </div>
        <div style={bottomDiv}>
          <div>
            <div aria-label="Add a new file" className="action-menu_button" title="Add a new file" style={actionButtonStyle('button8')} onMouseEnter={() => handleMouseEnter('button8')} onMouseLeave={handleMouseLeave} onClick={toggleOptions2}>
              <img className="action-menu_main-icon_1ktMc" draggable="false" src={moduleIcon} alt="Add file" style={actionIconStyle} />
            </div>
          </div>
          <div style={{ width: '100px', float: 'right' }}>
            {showOptions2 && (
              <div className="options-dropdown" style={hideButtonDiv}>
                {/* // that code for a pip button
                <div className='button' style={hideButton} onClick={handleOpen}>
                  <img draggable={false} className="dropdown-icon" src={pipIcon} alt="Option icon" title="Add a new Pip package" style={dropdownIconStyle('button5')} onMouseEnter={() => handleMouseEnter('button5')} onMouseLeave={handleMouseLeave} />
                </div>
                <WarningModal open={open} handleClose={handleClose} /> */}
                <div className='button' style={hideButton}>
                  <img draggable={false} className="dropdown-icon" src={extensionIcon} alt="Option icon" title="Add a new Extension" style={dropdownIconStyle('button6')} onClick={handleAddExtensionClick} onMouseEnter={() => handleMouseEnter('button6')} onMouseLeave={handleMouseLeave} />
                  <input type="file" id="fileInput" style={{ display: 'none' }} multiple onChange={handleFileInputChange} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

Sidebar.propTypes = {
  selectedSprite: PropTypes.shape({
    name: PropTypes.string,
    direction: PropTypes.number,
    size: PropTypes.number,
  }),
  // onItemSelected: PropTypes.func.isRequired,
  vm: PropTypes.object.isRequired, // Ensure VM is passed as a prop
  // extensionID: PropTypes.string.isRequired,
  sprites: PropTypes.array.isRequired,
  toggleOptions1: PropTypes.func.isRequired,
  toggleOptions2: PropTypes.func.isRequired,
  showOptions1: PropTypes.bool.isRequired,
  showOptions2: PropTypes.bool.isRequired,
  onOpenExtensionLibrary: PropTypes.func.isRequired,
  openEditor: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  sprites: Object.values(state.scratchGui.targets.sprites).map((sprite) => ({
    id: sprite.id,
    name: sprite.name,
  })),
  vm: state.scratchGui.vm // Ensure VM is available in props
});

const mapDispatchToProps = (dispatch) => ({
  onOpenExtensionLibrary: () => dispatch(openExtensionLibrary()),
  openEditor: (file) => dispatch({ type: 'OPEN_EDITOR', file })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Sidebar);
