import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import searchIcon from "./img/searchIcon.svg";
import deleteIcon1 from "./img/delete.svg";
import copyIcon from "./img/copyIcon.svg";
import SearchBox from "./SearchBox.jsx";

const Container = styled.div`
  font-family: monospace;
  font-weight: bold;
  color: #0d0d0d;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.5);
  overflow: hidden;
`;

const InputCarat = styled.div`
  color: #000;
  padding-right: 8px;
  display: flex;
`;

const InputLine = styled.div`
  margin-top: 8px;
  display: flex;
`;

const ActiveInputLine = styled.div`
  margin-top: 8px;
  display: flex;
`;

const Output = styled.div`
  color: #000;
  
  background-color:rgb(219 227 242);
  white-space: pre-wrap;
`;

const Error = styled.div`
  color: #d33;
  margin-top: 8px;
  white-space: pre-wrap;
`;

const TextInput = styled.input`
  color: #000;
  font-size: inherit;
  font-weight: bold;
  border: none;
  outline: none;
  flex-grow: 1;
  caret-color: #f48fb1;
  background-color: transparent;
  font-family: monospace;
`;

const Header = styled.div`
  display: flex;
`;


// const TerminalContent = styled.div`
//   padding: 16px;
//   padding-top: 2px;
//   height: ${props => (props.isExpanded ? '800px' : '200px')};
//   overflow-y: scroll;
//   -ms-overflow-style: none;
//   scrollbar-width: none; 
// `;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const Button = styled.div`
  cursor: pointer;
  margin-top: 10px;
`;

export const ReactRepl = ({
    title,
    tabs,
    selectedTab,
    isExpanded,
    onChangeTab,
    onClear,
    showDebugger,
    stealFocus1 = true, 
    stealFocus2 = true,
    onSubmit,
    lines,
    height
}) => {
    const inputRef = useRef();
    const terminalContentRef = useRef();
    const [activeInputValue, setActiveInputValue] = useState("");
    const [historySelectIndex, setHistorySelectIndex] = useState(-1);
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const [filteredResults, setFilteredResults] = useState([]);
    // const [outputArray, setOutputArray] = useState([{ type: '', value: '' }]);
    // const [searchTerm, setSearchTerm] = useState('');

    const toggleSearch = () => {
        setIsSearchOpen(!isSearchOpen);
        // Optionally, handle logic related to search visibility
    };

    const handleSearch = (query) => {
        // Implement search functionality and set filtered results
        // Example:
        // setSearchTerm(query)
        // const results = lines.filter((line) => line.value.includes(query));
        // setFilteredResults(results);
        const results = lines.filter((line) => line.value.includes(query));
        setFilteredResults(results);

    };

    const handleClose = () => {
        setIsSearchOpen(false);
        // setSearchTerm('');
    setFilteredResults([]);
        // Optionally, handle logic on search box close
    };

    // const termAction = (action) => {
    //     // Implement actions like "onClear" or "onCopy"
    //     // Example:
    //     if (action === "onCopy") {
    //         const lastOutput = outputArray[outputArray.length - 1];
    //         if (lastOutput && lastOutput.value) {
    //             navigator.clipboard.writeText(lastOutput.value).then(
    //                 () => console.log('Copied to clipboard'),
    //                 (err) => console.error('Could not copy: ', err)
    //             );
    //         }
    //     }

    //     if (action === "onClear") {
    //         // const newOutputArray = outputArray.filter(line => line.type !== "output");
    //         // setOutputArray(newOutputArray);
    //         setOutputArray([{ type: "", value: "" }]);
    //     } 

    //     if (action === "onSearch") {
    //         const searchTerm = prompt("Enter the term to search:");
    //         if (searchTerm) {
    //             const results = outputArray.filter(line => line.value.includes(searchTerm));
    //             if (results.length > 0) {
    //                 console.log('Search results:', results);
    //                 alert(Found`${results.length}`, results);
    //                 // Optionally, setFilteredResults(results); if you want to manage state
    //                 scrollToResult(0); // Scroll to the first result
    //             } else {
    //                 alert('No results found');
    //             }
    //         }
    //     }
    // };

    const termAction = (action) => {
        // Implement actions like "onClear" or "onCopy"
        if (action === "onClear") {
            onClear(); 
            // Call the provided clear function
        } else if (action === "onCopy") {
            // Implement copy functionality
            const outputText = lines
                .filter((line) => line.type === "output")
                .map((line) => line.value)
                .join("\n");
            navigator.clipboard.writeText(outputText);
        }
    };

    useEffect(() => {
        if (!terminalContentRef.current) return
        terminalContentRef.current.scrollTop =
            terminalContentRef.current.scrollHeight
    }, [lines])
    useEffect(() => setHistorySelectIndex(-1), [lines])

    return (
        <Container style={{ borderBottomRightRadius:'3%', height:'100%'}}>
            <Header onClick={() => (stealFocus2 ? inputRef.current.focus() : null)}>
                {/* <Title>{title}</Title> */}
                {isSearchOpen && (
                    <SearchBox onSearch={handleSearch} onClose={handleClose} text={lines.map(line => line.value).join('\n')} />
                )}
            </Header>

            <div style={{ display: 'flex' }} onClick={() => (stealFocus1 ? inputRef.current.focus() : null)}>
                <div style={{ width: showDebugger ? '80%' : ' 100%', }}>
                    <div ref={terminalContentRef} style={{
                          padding: '16px',
                          paddingTop: '2px',
                          height: isExpanded ? '800px' : '200px',
                          overflowY: 'scroll',
                          scrollbarWidth: 'none',
                    }}>
                    {filteredResults.length > 0 ? (
                            filteredResults.map((result, index) => (
                                <div key={index} style={{ backgroundColor: 'yellow' }}>{result.value}</div>
                            ))
                        ) : (
                            lines.map((line, i) =>
                            line.type === "input" ? (
                                <InputLine key={i}>
                                    <InputCarat>{">"}</InputCarat>
                                    {line.value}
                                </InputLine>
                            ) : line.type === "output" ? (
                                <Output key={i}>{line.value}</Output>
                            ) : (
                                <Error key={i}>{line.value.toString()}</Error>
                            )
                        )
                        )}
                        <ActiveInputLine>
                            <InputCarat>{">"}</InputCarat>
                            <TextInput
                                onKeyUp={(e) => {
                                    if (e.key === "Enter") {
                                        onSubmit(activeInputValue)
                                        setActiveInputValue("")
                                    } else if (e.key === "ArrowUp") {
                                        const newHSI = historySelectIndex + 1
                                        const inputs = lines.filter((l) => l.type === "input")
                                        inputs.reverse()
                                        if (newHSI < inputs.length) {
                                            setActiveInputValue(inputs[newHSI].value)
                                            setHistorySelectIndex(newHSI)
                                        }
                                    } else if (e.key === "ArrowDown") {
                                        const newHSI = historySelectIndex - 1
                                        const inputs = lines.filter((l) => l.type === "input")
                                        inputs.reverse()
                                        if (newHSI >= 0) {
                                            setActiveInputValue(inputs[newHSI].value)
                                            setHistorySelectIndex(newHSI)
                                        }
                                    }
                                }}

                                onChange={(e) => setActiveInputValue(e.target.value)}
                                value={activeInputValue}
                                ref={inputRef}
                            />
                        </ActiveInputLine>
                    </div>
                </div>
                <ButtonContainer>
                    <Button onClick={toggleSearch}>
                        <img
                            draggable={false}
                            src={searchIcon}
                            style={{
                                width: '30px',
                                height: '30px',
                                marginLeft: '10px',
                                marginBottom: '10px',
                                marginTop: '5px',
                            }}
                            alt="Search Logs"
                        />
                    </Button>

                    <Button
                        onClick={() => termAction("onClear")}
                        title="Clear Logs"
                    >
                        <img
                            draggable={false}
                            src={deleteIcon1}
                            style={{
                                width: '30px',
                                height: '30px',
                                marginLeft: '10px',
                                marginBottom: '10px',
                            }}
                            alt="Clear Logs"
                        />
                    </Button>
                    <Button
                        onClick={() => termAction("onCopy")}
                        title="Copy Output"
                    >
                        <img
                            draggable={false}
                            src={copyIcon}
                            style={{
                                width: '30px',
                                height: '30px',
                                marginLeft: '10px',
                            }}
                            alt="Copy Output"
                        />
                    </Button>
                </ButtonContainer>

            </div>
        </Container>
    );
};

export default ReactRepl;